import * as yup from 'yup';

const requiredMessage = 'Заполните это поле, оно обязательное';

export const employeeFormSchema = yup.object({
	first_name: yup.string().required(requiredMessage),
	last_name: yup.string().required(requiredMessage),
	middle_name: yup.string().required(requiredMessage),
	position: yup.string().nullable().notRequired(),
	date_of_work_start: yup.string().required(requiredMessage),
	custom_initials: yup.string().nullable().notRequired(),
});
