import { CanceledStatusModal } from '@/entities/tables/tech-card';
import {
	isEmptyObject,
	LINK_TO_TECH_CARD_PREFORM_INFO,
	Loader,
	showErrorToast,
	showSuccessToast,
	StandardConsumptionTypeEnum,
	STANDARD_CONSUMPTION_TYPES,
	SubmitModal,
	TechCardStatusColors,
	TechCardStatuses,
	TECH_CARDS_PATH,
	useAppSelector,
	MainContentLoader,
	useTechCard,
	DEFAULT_YEAR_FORMAT,
	DEFAULT_TIME_FORMAT,
	DEFAULT_DATE_FORMAT,
} from '@/shared';
import {
	PreformCustomNumUpdateRequest,
	PreformTechMapCreateRequest,
	useCancelPreformTechMapMutation,
	useCreatePreformTechMapMutation,
	useGetPreformTechMapByNumberQuery,
	useLazyGetFileQuery,
	useUpdatePreformTechMapMutation,
} from '@/shared/state/api/swagger';
import { Layout } from '@/widgets/layout';
import { useMapResize } from '@/widgets/tech-card/lib/use-map-resize';
import { usePreformFormConfig } from '@/widgets/tech-card/model/form-preform-config';
import { TechCardPreform } from '@/widgets/tech-card/tech-card-preform';
import { Box, Button, ButtonGroup, IconButton, TextHeader, Tooltip, useToast } from '@chakra-ui-kraud/react';
import { ArrowBackIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { isEmpty, isEqual } from 'lodash';
import { MadIcon } from 'madsoft-icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import styles from './tech-card-page.module.scss';
import { CancelModal } from './cancel-modal/cancel-modal';
import { PopoverTechCard } from './popover/popover-techcard';
import { prepareCreatePreformMap } from '@/widgets/tech-card/lib/prepare-create-preform-map';
import dayjs from 'dayjs';
import { NUM_REMAINDER_TOO_LOW, PRODUCT_TECH_MAP_CANCELLED } from '@/widgets/tech-card';
import { ActionsTechCardPreformMenu } from './actions-tech-card-menu/action-tech-card-preform-menu';
import {
	CANT_CHANGE_NUM_ERROR,
	RETURN_COUNT_ERROR,
	SGD_OUT_COUNT_TOO_LARGE,
	TECH_MAP_ALREADY_EXIST,
} from '@/widgets/tech-card/model/constants';
import printJS from 'print-js';

export const TechCardPreformPage = () => {
	const {
		// режим создания новой карты
		isCreationMode,
		// режим редактирования существующей карты
		isEditMode,
		setIsEditMode,
		isInitialEditMode,
		// изменение статуса карты
	} = useTechCard();

	const [create, { isLoading: isCreateLoading, data: createResponse }] = useCreatePreformTechMapMutation();
	const [update, { isLoading: isUpdateLoading }] = useUpdatePreformTechMapMutation();
	const [getAdditionalFile, { isFetching: isAdditionalFileFetching }] = useLazyGetFileQuery();

	const location = useLocation();
	const toast = useToast();
	const navigate = useNavigate();

	const [isResetMode, setIsResetMode] = useState(false);
	const printeableAreaRef = useRef<HTMLDivElement>(null);
	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
	const [isPrinting, setIsPrinting] = useState(false);
	const mapNumber = Number(useParams().number);
	const {
		data: preformMap,
		isFetching,
		isUninitialized,
		refetch,
	} = useGetPreformTechMapByNumberQuery(
		{ preformTechMapNumber: mapNumber },
		{
			skip: !mapNumber || Number.isNaN(mapNumber),
		},
	);

	// Без мемоизации меняется ссылка на функцию, что триггерит изменение handlePrint
	const handleAfterPrint = useCallback(() => {
		setIsPrinting(false);
		if (preformMap?.number) {
			navigate(LINK_TO_TECH_CARD_PREFORM_INFO(preformMap?.number), {
				state: { isPrinting: false },
			});
		}
	}, [preformMap]);
	// Без мемоизации меняется ссылка на функцию, что триггерит изменение handlePrint
	const handleAdditionalFileDownloadError = useCallback(() => {
		showErrorToast(toast, { description: 'При скачивании файла произошла ошибка' });
	}, []);

	const handlePrint = useReactToPrint({
		content: () => printeableAreaRef.current,
		removeAfterPrint: true,
		copyStyles: true,
		onAfterPrint: async () => {
			if (preformMap?.file?.id) {
				try {
					const file = (await getAdditionalFile({
						id: preformMap?.file?.id,
					}).unwrap()) as unknown as Blob;

					printJS({
						printable: URL.createObjectURL(file),
						onPrintDialogClose: handleAfterPrint,
						onError: handleAdditionalFileDownloadError,
					});
				} catch (error) {
					handleAdditionalFileDownloadError();
				}
			}
			handleAfterPrint();
		},
	});

	const formProps = usePreformFormConfig(isCreationMode, preformMap);

	useEffect(() => {
		// Для правильной печати необхоимо дождаться пока у нас пояфится информация о карте
		if (location.state?.isPrinting && printeableAreaRef.current && preformMap && !isFetching && !isUninitialized) {
			// если все готово, то меняем стейт на печать
			setIsPrinting((prev) => {
				// Если предыдущий стейт был false, то запускаем печать, чтобы избежать зацикливания и несколько повторных вызовов
				if (!prev) {
					// Так как нам нужно дождаться набивку пустыми полям, делаем это через таймаут
					setTimeout(() => {
						handlePrint();
					}, 10);
				}

				return true;
			});
		}
	}, [location.state, handlePrint, isFetching, isUninitialized, preformMap]);

	useMapResize();

	const handleCancelClick = () => {
		formProps.reset();
		setIsEditMode(false);
		if (isInitialEditMode || isCreationMode || isEditMode) {
			navigate(TECH_CARDS_PATH);
		}
	};

	const isLoaded = !isCreateLoading && !isFetching && !isUpdateLoading;

	const [submitModal, setSubmitModal] = useState<null | {
		remainder: string;
		standardConsumptionType: StandardConsumptionTypeEnum;
	}>(null);

	const handleCreateTechMap = useCallback(
		({ force }: { force: boolean }) => {
			formProps.handleSubmit((data) => {
				const preparedRequest = prepareCreatePreformMap(data as PreformTechMapCreateRequest);

				create({
					preformTechMapCreateRequest: preparedRequest,
					xDisableCatalogNumRemainderCheck: force,
				})
					.unwrap()
					.then((data) => {
						showSuccessToast(toast, {
							title: 'Заготовительная маршрутная карта создана',
							description: `Вы создали карту № ${data.number}`,
							duration: 6000,
						});
						setIsEditMode(false);

						if (data?.number) {
							navigate(LINK_TO_TECH_CARD_PREFORM_INFO(data?.number), {
								state: { isPrinting: true },
							});
						}
					})
					.catch((error) => {
						if (error?.status === NUM_REMAINDER_TOO_LOW) {
							setSubmitModal({
								remainder: error?.data?.detail?.remainder,
								standardConsumptionType: error?.data?.detail?.standard_consumption_type,
							});
							return;
						}

						showErrorToast(toast, {
							title: 'Не удалось сохранить',
							description: 'При создании карты произошла ошибка',
						});
					});
				setIsEditMode(false);
				setIsPrinting(true);
				setSubmitModal(null);
			})();
		},
		[create, formProps, navigate, setIsEditMode, toast],
	);

	const userRole = useAppSelector((state) => state.auth.userProfile?.role);

	const handleSubmitTechMap = useCallback(async () => {
		if (!preformMap?.id) {
			return;
		}

		const initialValues = formProps.formState.defaultValues;
		const values = formProps.getValues();

		const operations = values?.preform_operations?.filter((el) => el.step_id || el.name) || [];
		const updateOperations = operations
			.filter((el) => el.id)
			.map((el) => ({
				...el,
				date: el.date && dayjs(el.date).format('YYYY-MM-DD'),
				wasted: el.wasted ? (Number(el.wasted) > 0 || Number.isNaN(Number(el.wasted)) ? el.wasted : '0') : '0',
			}));
		const createOperations = operations
			.filter((el) => !el.id)
			.map((el) => ({
				...el,
				date: el.date && dayjs(el.date).format('YYYY-MM-DD'),
				wasted: el.wasted ? (Number(el.wasted) > 0 || Number.isNaN(Number(el.wasted)) ? el.wasted : '0') : '0',
			}));
		const sgdOutToUpdate = values?.preform_sgd_out?.filter((sgd) => sgd.id && sgd.product_tech_map_receiver_id);
		const sgdOutToCreate = values?.preform_sgd_out?.filter((sgd) => !sgd.id && sgd.product_tech_map_receiver_id);

		const sgdPayload = {
			preform_sgd_out_to_update: isEmpty(sgdOutToUpdate) ? undefined : sgdOutToUpdate,
			preform_sgd_out_to_create: isEmpty(sgdOutToCreate) ? undefined : sgdOutToCreate,
		};
		const operationsPayload = {
			preform_operations_to_update: isEmpty(updateOperations) ? undefined : updateOperations,
			preform_operations_to_create: isEmpty(createOperations) ? undefined : createOperations,
		};

		update({
			preformTechMapId: preformMap?.id,
			bodyUpdatePreformTechMap: {
				preform_tech_map_payload: {
					created_at: dayjs(values.created_at, DEFAULT_YEAR_FORMAT, true).isValid()
						? dayjs(values.created_at, DEFAULT_YEAR_FORMAT, true).format(DEFAULT_DATE_FORMAT) +
						  ' ' +
						  dayjs().format(DEFAULT_TIME_FORMAT)
						: undefined,
					number: values.number,
					d_otp: values.d_otp,
					fio_pds: values.fio_pds,
				},
				preform_payload: values.preform,
				preform_operations_payload: isEmptyObject(operationsPayload) ? undefined : operationsPayload,
				preform_num_payload: {
					...values.preform_num,
					id: preformMap.preform_num?.id,
					date_of_manufacture: !values?.preform_num?.date_of_manufacture
						? undefined
						: values.preform_num.date_of_manufacture,
				} as PreformCustomNumUpdateRequest,
				preform_plan_payload: isEqual(initialValues?.preform_plan, values.preform_plan)
					? undefined
					: values.preform_plan,
				preform_sgd_out_payload: isEmptyObject(sgdPayload) ? undefined : sgdPayload,
			},
		})
			.unwrap()
			.then(() => {
				showSuccessToast(toast, {
					title: 'Маршрутная карта обновлена',
					description: `Вы отредактировали карту № ${values?.number}`,
				});

				setIsEditMode(false);

				if (!!values?.number && initialValues?.number?.toString() !== values.number?.toString()) {
					navigate(LINK_TO_TECH_CARD_PREFORM_INFO(Number(values?.number)));
				} else {
					formProps.reset();
					refetch();
				}
			})
			.catch((err) => {
				if (err?.status) {
					switch (err?.status) {
						case PRODUCT_TECH_MAP_CANCELLED: {
							showErrorToast(toast, {
								title: 'Не удалось сохранить карту',
								description: 'Выбранная МК на деталь была отменена',
							});
							break;
						}
						case NUM_REMAINDER_TOO_LOW: {
							showErrorToast(toast, {
								title: 'Не удалось выдать материал',
								description: `На складе осталось ${err?.data?.detail?.remainder} материала`,
							});
							break;
						}
						case RETURN_COUNT_ERROR: {
							showErrorToast(toast, {
								title: 'Не удалось сохранить карту',
								description: `Вы не можете вернуть материала больше чем ${err?.data?.detail?.available_return_count}`,
							});
							break;
						}
						case TECH_MAP_ALREADY_EXIST: {
							showErrorToast(toast, {
								title: 'Не удалось сохранить карту',
								description: `Карта №
            ${values.number}
             уже существует`,
							});
							break;
						}
						case SGD_OUT_COUNT_TOO_LARGE: {
							showErrorToast(toast, {
								title: 'Не удалось выдать материал',
								description: `У вас осталось ${err?.data?.detail?.accepted_at_sgd_count} материала`,
							});
							break;
						}
						case CANT_CHANGE_NUM_ERROR: {
							showErrorToast(toast, {
								title: 'Ошибка редактирования НУМ',
								description: `Техкарта в работе, нельзя отредактировать НУМ`,
							});
							break;
						}
						default: {
							showErrorToast(toast, {
								description: 'При сохранении заготовительной МК произошла ошибка',
							});
						}
					}
				}
				formProps.reset();
			});
	}, [formProps, navigate, preformMap, refetch, setIsEditMode, toast, update, userRole]);

	const [cancelPreformTechMapMutation] = useCancelPreformTechMapMutation();

	const cancelPreformTechMap = useCallback(() => {
		cancelPreformTechMapMutation({ preformTechMapId: Number(preformMap?.id) })
			.unwrap()
			.then(() => {
				refetch();
				showSuccessToast(toast, { description: `Карта № ${preformMap?.number} аннулирована` });
			})
			.catch(() => showErrorToast(toast, { description: 'При аннулировании МК произошла ошибка' }));
	}, [cancelPreformTechMapMutation, preformMap, refetch, toast]);

	return (
		<>
			<Layout
				withSidebar={false}
				backArrow={
					<Button
						as={IconButton}
						icon={<ArrowBackIcon />}
						size="md"
						variant="ghost"
						colorScheme="tertiary"
						backgroundColor="transparent"
						onClick={() => {
							if (!isEqual(formProps.formState.defaultValues, formProps.getValues()) && isEditMode) {
								setIsCancelModalOpen(true);
							} else {
								handleCancelClick();
								navigate(TECH_CARDS_PATH);
							}
						}}
					/>
				}
				title={
					<>
						<TextHeader className={styles['tech-card__title']} variant="h1" mb="0">
							{isCreationMode
								? 'Создание карты'
								: isEditMode
								? `Редактирование карты № ${preformMap?.number}`
								: `Маршрутная карта № ${preformMap?.number}`}
						</TextHeader>
						{preformMap?.status && (
							<span
								style={{
									backgroundColor: `${TechCardStatusColors[preformMap?.status || '']}`,
									width: '8px',
									height: '8px',
									minWidth: '8px',
									minHeight: '8px',
									borderRadius: '50%',
								}}
							/>
						)}
					</>
				}
				headerLeftButton={
					isEditMode && !isCreationMode ? (
						<ButtonGroup>
							<Button
								size="md"
								variant="ghost"
								colorScheme="tertiary"
								onClick={() => {
									if (!isEqual(formProps.formState.defaultValues, formProps.getValues())) {
										setIsCancelModalOpen(true);
									} else {
										handleCancelClick();
									}
								}}
								tabIndex={-1}
							>
								Отменить
							</Button>
							<Tooltip isDisabled={formProps.formState.isValid} label={'Заполните нужные поля'}>
								<Button
									size="md"
									variant="ghost"
									colorScheme="primary"
									rightIcon={
										<MadIcon
											module="basic"
											type="outline"
											size="default"
											mode="default"
											name="chevron-right"
										/>
									}
									onClick={() => {
										if (isEqual(formProps.formState.defaultValues, formProps.getValues())) {
											setIsEditMode(false);
										} else {
											handleSubmitTechMap();
										}
									}}
									tabIndex={-1}
									isDisabled={!isEmpty(formProps.formState.errors) || !isLoaded}
								>
									Сохранить изменения
								</Button>
							</Tooltip>
						</ButtonGroup>
					) : !isEditMode && !isCreationMode ? (
						preformMap?.status === TechCardStatuses.canceled ? null : (
							<Button
								size="md"
								variant="ghost"
								colorScheme="tertiary"
								leftIcon={
									<MadIcon
										module="basic"
										size="default"
										mode="default"
										type="outline"
										name="pencil-square"
									/>
								}
								onClick={() => setIsEditMode(true)}
							>
								Отредактировать
							</Button>
						)
					) : (
						<Tooltip label={'Заполните обязательные поля'} isDisabled={!!formProps.formState.isValid}>
							<Button
								size="md"
								variant="ghost"
								colorScheme="primary"
								lineHeight="24px"
								rightIcon={<ChevronRightIcon />}
								isDisabled={!formProps.formState.isValid || isCreateLoading || !!createResponse}
								onClick={() => handleCreateTechMap({ force: false })}
								tabIndex={-1}
							>
								Создать и напечатать
							</Button>
						</Tooltip>
					)
				}
				headerRightButton={
					!isCreationMode &&
					!isEditMode && (
						<ButtonGroup>
							<Button
								size="md"
								variant="ghost"
								colorScheme="tertiary"
								leftIcon={
									<MadIcon
										module="basic"
										type="outline"
										size="default"
										mode="default"
										name="printer"
									/>
								}
								onClick={() => {
									setIsPrinting(true);
									setTimeout(() => {
										handlePrint();
									}, 10);
								}}
							>
								Распечатать
							</Button>
							<ActionsTechCardPreformMenu setIsResetMode={setIsResetMode} />
						</ButtonGroup>
					)
				}
			>
				<form autoComplete="off">
					<CancelModal
						isCreationMode={isCreationMode}
						isCancelModalOpen={isCancelModalOpen}
						onClose={() => setIsCancelModalOpen(false)}
						handleMainAction={() => {
							if (isEqual(formProps.formState.defaultValues, formProps.getValues())) {
								setIsEditMode(false);
								navigate(TECH_CARDS_PATH);
							}
						}}
						handleCancelClick={handleCancelClick}
					/>
					<FormProvider {...formProps}>
						{isLoaded ? (
							<>
								<TechCardPreform
									isEditable={isEditMode}
									ref={printeableAreaRef}
									isPrinting={isPrinting}
									focusedBlock={location.state?.focusedBlock ? location.state.focusedBlock : null}
								/>
								<PopoverTechCard
									isCreationMode={isCreationMode}
									isInitialEditMode={isInitialEditMode}
									isEditMode={isEditMode}
								/>
							</>
						) : (
							<Loader />
						)}
						{isAdditionalFileFetching ? (
							<MainContentLoader color="white" position={'fixed'} withOverlay>
								<Box fontSize={'md'} color="white" fontWeight={500}>
									Загружаем приложение к МК...
								</Box>
							</MainContentLoader>
						) : (
							<></>
						)}
					</FormProvider>
				</form>
			</Layout>
			{isResetMode && (
				<CanceledStatusModal
					isOpen={isResetMode}
					onClose={() => setIsResetMode(false)}
					onCancelTechMap={cancelPreformTechMap}
				/>
			)}
			{submitModal && (
				<SubmitModal
					isOpen={!!submitModal}
					onClose={() => setSubmitModal(null)}
					onSubmit={() => handleCreateTechMap({ force: true })}
					text={`Сейчас на складе ${submitModal.remainder} ${
						STANDARD_CONSUMPTION_TYPES[submitModal.standardConsumptionType]
					}. материала. Вы уверены, что хотите создать карту с таким материалом?`}
					closeBtnText="Изменить материал"
					submitBtnText="Создать карту"
				/>
			)}
		</>
	);
};
