import {
	showErrorToast,
	showSuccessToast,
	SideModal,
	StandardConsumptionTypeEnum,
	STANDARD_CONSUMPTION_TYPES,
	SubmitModal,
} from '@/shared';
import {
	useCreateTechMapMutation,
	useGetProductsQuery,
	useGetTechMapByNumberQuery,
	useRecreateTechMapMutation,
} from '@/shared/state/api/swagger';
import { useToast } from '@chakra-ui-kraud/toast';
import { Button, ButtonGroup } from '@chakra-ui/button';
import { FC, useRef, useState, useCallback } from 'react';
import { TechCardFormModalProps, FormT, FormHandle } from '../model/tech-card-form.types';
import { getTechCardName } from '@/shared/core/utils/tech-card-utils';
import { TechCardForm } from './tech-card-form';
import { prepareTechCardCreateBody } from '../model/tech-card-create-body';
import { isFetchBaseQueryError } from '@/shared/state/api';
import {
	PREFORM_TECH_MAP_LINKED_ERROR,
	PRODUCT_TECH_MAP_LINKED_ERROR,
	TECH_PROCESS_ALREADY_STARTED_ERROR,
	NUM_REMAINDER_TOO_LOW,
	ASSEMBLY_TECH_MAP_LINKED_ERROR,
} from '../model/error-codes';
import { useParams } from 'react-router-dom';

export const TechCardFormModal: FC<TechCardFormModalProps> = ({
	onCreate,
	onClose,
	isOpen,
	product,
	variant = 'recreate',
	disableProductSelect,
	techMapNumber,
}) => {
	const toast = useToast();
	const formRef = useRef<FormHandle>(null);

	const [create, { isLoading }] = useCreateTechMapMutation();
	const { selectedProduct, isFetching } = useGetProductsQuery(
		{
			nameStrict: product?.product_name,
			symbolStrict: product?.product_symbol,
		},
		{
			selectFromResult: (result) => ({
				...result,
				selectedProduct: result?.data?.payload?.[0],
			}),
			skip: !product,
		},
	);

	const [submitModal, setSubmitModal] = useState<null | {
		remainder: string;
		standardConsumptionType: StandardConsumptionTypeEnum;
	}>(null);

	const [recreate, { isLoading: isRecreateLoading }] = useRecreateTechMapMutation();
	const mapNumber = Number(useParams().number);
	const { data: techMap } = useGetTechMapByNumberQuery(
		{ productTechMapNumber: mapNumber },
		{
			skip: !mapNumber || Number.isNaN(mapNumber),
		},
	);

	const handleRecreate = useCallback(
		(data: FormT, force?: boolean) => {
			if (techMap?.id) {
				recreate({
					productTechMapRequestCreate: prepareTechCardCreateBody(data),
					xDisableCatalogNumRemainderCheck: force,
					productTechMapId: techMap.id,
				})
					.unwrap()
					.then((data) => {
						showSuccessToast(toast, {
							title: 'Маршрутная карта была пересоздана',
							description: `Вы пересоздали карту № ${getTechCardName(data)}`,
							duration: 6000,
						});
						onClose();
					})
					.catch((err) => {
						if (isFetchBaseQueryError(err)) {
							switch (err.status) {
								case NUM_REMAINDER_TOO_LOW: {
									showErrorToast(toast, {
										title: '',
										description: '',
									});
									break;
								}
								case PRODUCT_TECH_MAP_LINKED_ERROR: {
									showErrorToast(toast, {
										title: 'Не удалось пересоздать маршрутную карту',
										description: 'Маршрутная карта связана с другой маршрутной картой на деталь',
									});
									break;
								}
								case ASSEMBLY_TECH_MAP_LINKED_ERROR: {
									showErrorToast(toast, {
										title: 'Не удалось пересоздать маршрутную карту',
										description: 'Маршрутная карта связана с другой сборочной маршрутной картой',
									});
									break;
								}
								case PREFORM_TECH_MAP_LINKED_ERROR: {
									showErrorToast(toast, {
										title: 'Не удалось пересоздать маршрутную карту',
										description:
											'Маршрутная карта связана с другой заготовительной маршрутной картой',
									});
									break;
								}
								case TECH_PROCESS_ALREADY_STARTED_ERROR: {
									showErrorToast(toast, {
										title: 'Не удалось пересоздать маршрутную карту',
										description: 'Техпроцесс уже начат',
									});
									break;
								}
								default: {
									showErrorToast(toast, {
										title: 'Не удалось пересоздать маршрутную карту',
										description: 'При пересоздании карты произошла ошибка',
									});
									break;
								}
							}
						}
					});
			}
		},
		[onClose, recreate, techMap?.id, toast],
	);

	const handleCreate = (data: FormT, force?: boolean) => {
		create({
			productTechMapRequestCreate: prepareTechCardCreateBody(data),
			'x-disable-catalog-num-remainder-check': force ? 'true' : undefined,
		})
			.unwrap()
			.then((data) => {
				showSuccessToast(toast, {
					title: 'Маршрутная карта создана',
					description: `Вы создали карту № ${getTechCardName(data)}`,
					duration: 6000,
				});
				onClose();
				onCreate?.(data);
			})
			.catch((error) => {
				if (error.status === 479) {
					setSubmitModal({
						remainder: error?.data?.detail?.remainder,
						standardConsumptionType: error?.data?.detail?.standard_consumption_type,
					});
				} else {
					showErrorToast(toast, {
						title: 'Не удалось сохранить',
						description: 'При создании карты произошла ошибка',
					});
				}
			});
	};

	const onSubmit = variant === 'create' ? handleCreate : handleRecreate;

	return (
		<SideModal
			title={variant === 'recreate' ? `Пересоздание МК №${techMapNumber}` : 'Создание связи с новой МК'}
			onClose={onClose}
			isOpen={isOpen}
			footer={
				<ButtonGroup>
					<Button
						size="md"
						variant="ghost"
						colorScheme="tertiary"
						onClick={() => {
							onClose();
						}}
					>
						Отменить
					</Button>
					<Button
						size="md"
						variant="solid"
						colorScheme="primary"
						onClick={() => {
							formRef?.current?.onSubmit();
						}}
						isDisabled={isLoading || isRecreateLoading}
						isLoading={isLoading || isRecreateLoading}
					>
						Создать
					</Button>
				</ButtonGroup>
			}
		>
			{!isFetching && (
				<TechCardForm
					ref={formRef}
					onSubmit={onSubmit}
					product={selectedProduct}
					disableProductSelect={disableProductSelect}
				/>
			)}
			{submitModal && (
				<SubmitModal
					isOpen={!!submitModal}
					onClose={() => setSubmitModal(null)}
					onSubmit={() => formRef.current && onSubmit(formRef?.current?.getValues(), true)}
					text={`Сейчас на складе ${submitModal.remainder} ${
						STANDARD_CONSUMPTION_TYPES[submitModal.standardConsumptionType]
					}. материала. Вы уверены, что хотите создать карту с таким материалом?`}
					closeBtnText="Изменить материал"
					isLoading={isLoading}
					submitBtnText="Создать карту"
				/>
			)}
		</SideModal>
	);
};
